import React from 'react'
import Farmaoffice from './assets/farmaoffice.png'
import BI from './assets/bi.png'
import AreaPrivada from './assets/areaprivada.png'
import Campus from './assets/campus.png'
import Farmapremium from './assets/farmapremium.png'
import Planograma from './assets/planograma.png'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import PropTypes from 'prop-types'

const FooterInfo = ({ height, width }) => {
  return (
    <div className={'login-footer-info tw-px-0 tw-bg-transparent tw-flex tw-justify-between tw-gap-2 tw-px-7'}>
      <div style={{ width: '8.38rem' }}>
        <Paragraphs className={'tw-text-white tw-leading-5'} size={(height >= 750 && width >= 1400) ? 'base' : 'sm'} weight={'regular'}>
          El nuevo entorno cloud te permite acceder a todas tus herramientas digitales desde este único login:
        </Paragraphs>
      </div>
      <div style={{ width: '9.55rem' }}>
        <Paragraphs className={'tw-text-white tw-leading-5'} size={height >= 750 && width >= 1400 ? 'base' : 'sm'} weight={'regular'}>
          El nou entorn cloud et permet accedir a totes les teves eines digitals des d&apos;aquest únic login:
        </Paragraphs>
      </div>
      <div className={'tw-flex'}>
        <div className={'tw-rounded-full tw-self-center tw-bg-black tw-bg-opacity-5 '}>
          <img src={Farmaoffice} className={'tw-object-scale-down tw-p-5'} height={'100%'}/>
        </div>
        <Paragraphs className={'tw-text-white tw-self-center tw-pl-2'} size={'base'} weight={'bold'}>Farmaoffice</Paragraphs>
      </div>
      <div className={'tw-flex tw-w-44'} >
        <div className={'tw-rounded-full tw-self-center tw-bg-black tw-bg-opacity-5 '}>
          <img src={BI} className={'tw-object-scale-down tw-p-5'}/>
        </div>
        <Paragraphs className={'tw-text-white tw-self-center tw-pl-2 tw-w-20'} size={'base'} weight={'bold'}>Business Intelligence</Paragraphs>
      </div>
      <div className={'tw-flex tw-pl-4'}>
        <div className={'tw-rounded-full tw-self-center tw-bg-black tw-bg-opacity-5 '}>
          <img src={AreaPrivada} className={'tw-object-scale-down tw-p-5'}/>
        </div>
        <Paragraphs className={'tw-text-white tw-self-center tw-pl-2'} size={'base'} weight={'bold'}>Àrea Privada</Paragraphs>
      </div>
      <div className={'tw-flex'}>
        <div className={'tw-rounded-full tw-self-center tw-bg-black tw-bg-opacity-5 '}>
          <img src={Campus} className={'tw-object-scale-down tw-p-5'}/>
        </div>
        <Paragraphs className={'tw-text-white tw-self-center tw-pl-2'} size={'base'} weight={'bold'}>Campus</Paragraphs>
      </div>
      <div className={'tw-flex'}>
        <div className={'tw-rounded-full tw-self-center tw-bg-black tw-bg-opacity-5 '}>
          <img src={Farmapremium} className={'tw-object-scale-down tw-p-5'}/>
        </div>
        <Paragraphs className={'tw-text-white tw-self-center tw-pl-2'} size={'base'} weight={'bold'}>Farmapremium</Paragraphs>
      </div>
      <div className={'tw-flex'}>
        <div className={'tw-rounded-full tw-self-center tw-bg-black tw-bg-opacity-5 '}>
          <img src={Planograma} className={'tw-object-scale-down tw-p-5'} width={'98%'}/>
        </div>
        <Paragraphs className={'tw-text-white tw-self-center tw-pl-4'} size={'base'} weight={'bold'}>Planograma</Paragraphs>
      </div>
    </div>
  )
}

FooterInfo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
}
export default FooterInfo
