import { LOGOUT_USER, SET_TOKEN_USER, UPDATE_USER } from '../constants/user'

export const actionSetTokenUser = (data) => ({
  type: SET_TOKEN_USER,
  data
})

export const actionLogoutUser = (data) => ({
  type: LOGOUT_USER,
  data
})

export const actionUpdateUser = (data) => ({
  type: UPDATE_USER,
  data
})
