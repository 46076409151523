import React from 'react'
import PropTypes from 'prop-types'
import {
  // Sidebar,
  CustomHeaderLayout
  // Paragraphs
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { LayoutContainer } from './styles'
import { useSelector } from 'react-redux'
import { useUser } from '../../hooks/useUser/useUser'
import LangSelector from '../LangSelector'
import Sidebar from '../../../components/Sidebar/Sidebar'

const Layout = ({ children }) => {
  const { user } = useSelector((state) => state.userReducer)
  const { logoutUser } = useUser()

  return (
    <LayoutContainer className="layout tw-h-screen tw-overflow-hidden tw-relative tw-flex tw-flex-col tw-bg-gray-50 tw-relative">
      <div className="tw-absolute tw-w-full tw-top-0">
        <CustomHeaderLayout
          user={user}
          onCloseSesion={logoutUser}
        >
          <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
            <div className="tw-h-full tw-flex tw-items-center">
              {/* <Paragraphs className="tw-text-white">fedefarma</Paragraphs> */}
            </div>
            <LangSelector />
          </div>
        </CustomHeaderLayout>
      </div>
      <div className="tw-h-full tw-w-full tw-flex">
        <Sidebar />
        <div className="tw-flex-1">{children}</div>
      </div>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Layout
