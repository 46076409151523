import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useComporativeColor } from '../../hooks/useCorporativeColor/useComporativeColor'
import { GlobalStyle } from '../../routes/styles'
import { actionSetTheme } from '../../redux/actions/colors'

const ThemeProvider = ({ children }) => {
  const { colors } = useSelector((state) => state.colorsReducer)
  const dispatch = useDispatch()
  const { getCorporativeInfo } = useComporativeColor()

  /**
   * Confirm url xarxafarma for custom theme
   *
   * @author   ehernandez
   */
  useLayoutEffect(() => {
    const { href } = window.location
    if (!href.includes('xarxafarma')) {
      getCorporativeInfo('xarxafarma')
      dispatch(actionSetTheme('xarxafarma'))
    } else {
      getCorporativeInfo()
      dispatch(actionSetTheme())
    }
  }, [])

  return (
    <>
      {colors && <GlobalStyle paleta={colors} />}
      {children}
    </>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ThemeProvider
