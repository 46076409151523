import React, { useEffect, useState } from 'react'
// import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { useTranslation, Trans } from 'react-i18next'
import SpiralCloudBGFull from './assets/SpiralCloudBGFull.png'
import useWindowDimensions from '../../../Login/pages/LoginScreen/hooks/useWindowsDimensions'
import MigrationsButton from '../../../../components/MigrationsButton'

const InsideMigration = () => {
  const { t, i18n } = useTranslation()
  const [destination, setDestination] = useState('')
  console.log({ lang: i18n.language })
  const { width } = useWindowDimensions()
  const sectionStyle = {
    width: '100%',
    height: '100%',
    backgroundImage: width > 412 && `url(${SpiralCloudBGFull})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#359dd2',
    backgroundRepeat: 'no-repeat'
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const lang = queryParams.get('lang')
    if (lang) {
      i18n.changeLanguage(lang)
    }
    setDestination(queryParams.get('link') || '')
  }, [])

  return (
    <div className="login tw-flex tw-h-screen tw-fixed tw-bg-blue-500" style={sectionStyle}>
      <div
        className={'tw-flex tw-flex-col tw-justify-start tw-items-start tw-absolute tw-leading-tight'}
        style={{ top: width > 412 ? '18vh' : '1vh', marginLeft: `${width > 412 ? (width * 14) / 100 : 5}px`, width: `${width > 412 ? '100vh' : '100%'}` }}
      >
        <Trans
          i18nKey={'migration.newTimes'}
          t={t}
          components={{
            p: <div style={{ fontFamily: 'Atkinson Hyperlegible Bold', color: 'white', fontWeight: 'bold', fontSize: '8vh', marginLeft: width > 412 && '-2vh', textAlign: width < 413 && 'center' }}/>,
            italic: <i/>,
            a: <a/>,
            br: <br/>
          }}
        />
        <div className={'tw-flex tw-flex-col'} style={{ width: width > 412 ? '51vh' : '300px', marginLeft: width > 412 ? '23vh' : '6.5vh', textAlign: width < 413 && 'center' }}>
          <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '2.3vh', paddingTop: '2vh' }}>
            {t('migration.months')}
          </p>
          <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '2.3vh', paddingTop: '2vh' }}>
            {t('migration.now')}
          </p>
          <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '2.3vh', paddingTop: '2vh' }}>
            {t('migration.follow')}
          </p>
          <Trans i18nKey={'migration.coopWeb'} t={t} components={{
            p: <div style={{
              fontFamily: 'Atkinson Hyperlegible Regular',
              color: 'white',
              textDecoration: 'underline',
              fontSize: '2.3vh',
              paddingTop: '2vh'
            }}/>,
            italic: <i/>,
            a: <a href={destination} noreferer/>,
            br: <br/>
          }}/>
          <div className={'tw-pt-10'}>
            <MigrationsButton destination={destination} label={t('migration.access')} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default InsideMigration
