import React from 'react'
import { Switch, BrowserRouter as Router } from 'react-router-dom'
import { authProtectedRoutes, publicRoutes } from './infrastructure/routes'
import AppRoute from './infrastructure/routes/route'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './infrastructure/redux/store'
import { Provider } from 'react-redux'
import ThemeProvider from './infrastructure/context/ThemeProvider'

function App () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
         {/* <Router basename={'/sferafarma'}> */}
          <Router>
          <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}
              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected
                  Layout={route.layout}
                />
              ))}

          </Switch>
        </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
