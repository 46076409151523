import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfoCorporate } from '../../../../infrastructure/hooks/useInfoCorporate/useInfoCorporate'
import ItemSoftwares from '../../components/ItemSoftware/ItemSoftwares'
import useQuery from '../../../../infrastructure/hooks/useQuery'
import useMutation from '../../../../infrastructure/hooks/useMutation'

const HomeScreen = () => {
  // custom hooks
  const query = useQuery()
  const { getApps, apps } = useInfoCorporate()
  const { t } = useTranslation()
  const [callbackFO] = useMutation('/callback-farmapremium')

  const [loading, setLoading] = useState(false)
  const { info } = useInfoCorporate()

  /**
   * confirm redirect from framapremium
   * @author   ehernandez
   */
  const confirmRedirect = async () => {
    const code = query.get('code')
    const state = query.get('state')
    if (code && state) {
      setLoading(true)
      const { errors } = await callbackFO({
        method: 'post',
        data: { code, state }
      })
      if (!errors) {
        getApps()
      }
      setLoading(false)
    }
  }

  /**
   * get aplications and confirm redirect from framapremium
   * @author   ehernandez
   */
  useEffect(() => {
    getApps()
    confirmRedirect()
  }, [])

  /**
   * open software on new chrome tab
   * @author   ehernandez
   * @param   {Object} app
   * @param   {Object}  app.url
   * @param   {Object}  app.comprado
   */

  const openSotware = (app) => {
    if (app?.comprada) {
      const token = app.token || ''
      window.open(app.url + token + '?theme=fededarma')
    } else {
      window.open(app.url)
    }
  }
  /**
   * calcule the number of columns and rezie the item
   * @author   ehernandez
   */
  const getCol = (a, index) => {
    if (a.length === index + 1 && index % 2 === 0) {
      return 'full'
    }
    return '6/12'
  }

  /**
   * function to calculate height of banner from window height
   * @author   ehernandez
   */
  const getBannerHeight = () => {
    const height = window.innerHeight
    const bannerHeight = height * 0.5 - 52
    return bannerHeight + 'px'
  }

  const noComprado = apps.filter((i, index) => !i.comprada && i.id !== 7)
  const comprados = apps.filter((i, index) => i.comprada)
  return (
    <div className="home-screen tw-h-full tw-flex tw-flex-col sm:tw-flex-row ">
      {loading && (
        <div className="tw-fixed tw-top-0 tw-left-0 tw-bg-black tw-bg-opacity-10 tw-h-screen tw-w-screen"></div>
      )}
      <div className="tw-overflow-auto tw-flex-1">
        <Paragraphs weight="bold" className="tw-p-4" uppercase>
          {t('menu.Tus aplicaciones')}
        </Paragraphs>
        <div className="home-screen-softwares tw-flex tw-flex-wrap">
          {comprados.map(
            (i, index) =>
              i.comprada && (
                <motion.div
                  onClick={() => openSotware(i)}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ transition: 1.2, delay: index * 0.2 }}
                  key={i.id}
                  style={{ height: getBannerHeight(), minHeight: '280px' }}
                  className={`tw-w-full tw-px-2 tw-py-2 tw-w-full md:tw-w-${getCol(
                    comprados,
                    index
                  )}`}
                >
                  <ItemSoftwares
                    image={i.logo}
                    name={t(`menu.${i.nombre}`).toUpperCase()}
                    notifications={true}
                    description={t(`menu.${i.descripcion}`)}
                    btnText={t('menu.Abrir')}
                  />
                </motion.div>
              )
          )}
        </div>
        {(noComprado.length > 0 && !info?.xarxafarma && !info?.farmactiva) && (
          <div>
            <Paragraphs weight="bold" className="tw-p-4" uppercase>
              {t('menu.También te puede interesar')}
            </Paragraphs>
            <div className="home-screen-softwares tw-flex tw-flex-wrap">
              {noComprado.map(
                (i, index) =>
                  !i.comprada && (
                    <motion.div
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ transition: 1.2, delay: index * 0.2 }}
                      key={i.id}
                      style={{ height: getBannerHeight() }}
                      className={
                        'tw-w-full tw-my-3 tw-px-2 tw-w-full md:tw-w-4/12 '
                      }
                    >
                      <ItemSoftwares
                        onClick={() => openSotware(i)}
                        image={i.logo}
                        name={t(`menu.${i.nombre}`)}
                        notifications={true}
                        description={t(`menu.${i.descripcion}`)}
                        btnText={t('menu.Ver más')}
                      />
                    </motion.div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomeScreen
