import React, { useState, useEffect } from 'react'
import {
  Button,
  // Hero,
  Paragraphs,
  TextInput
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import Logo from '../../../../components/Logo'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { forms } from '../../constants/forms'
import useLogin from '../../hooks/useLogin'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Background from './assets/background.png'
import FooterInfo from './components/FooterInfo'
import useWindowDimensions from './hooks/useWindowsDimensions'
// import Themes from '../../components/themes/index'

const LoginScreen = () => {
  const { t } = useTranslation()

  /// custom hooks
  const { onLogin, loading } = useLogin()
  const [error, setError] = useState(null)
  const history = useHistory()
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })

  const { height, width } = useWindowDimensions()
  const [formStyle, setFormStyle] = useState({})
  useEffect(() => {
    if (width >= 1280) {
      setFormStyle({ width: '471px', height: '579px', left: '-120%', top: '-60%' })
      return
    }
    if (width >= 680) {
      setFormStyle({ width: '471px', height: '579px', left: '25%', top: '25%' })
      return
    }
    setFormStyle({ width: '360px', height: '579px' })
  }, [width])
  // console.log({ height, width })
  // redux
  const { colors, loading: loadingColors } = useSelector(
    (state) => state.colorsReducer
  )

  /**
   * form submit for login form
   *
   *
   * @author   ehernandez
   * @param    {Object} data element
   * @param    {String}   data.username
   * @param    {String}   data.password
   */
  const onSubmit = async (values) => {
    setError(null)
    const { success } = await onLogin(values)
    if (!success) {
      setError({
        message: 'Credenciales no válidas'
      })
      return
    }
    history.push('/')
  }

  /**
   * for show the form
   * @returns {JSX.Element}
   * @constructor
   */
  const ShowForm = () => {
    return (<form
      onSubmit={handleSubmit(onSubmit)}
      className="tw-flex tw-flex-col tw-items-center tw-bg-white tw-w-full"
    >
      <div className="tw-pt-10 tw-w-full">
        <div className="tw-p-4 tw-text-center tw-w-full">
          <Paragraphs size="base" color={colors?.color_primario}>
            {t('login.welcome', { name: colors?.nombre })}
          </Paragraphs>
          <Paragraphs size="5xl">
            {t('login.brand', { name: colors?.nombre })}
          </Paragraphs>
        </div>
        <div className={'tw-pt-10 tw-px-10 '}>
          {forms.login.map((i) => (
            <div key={i.id} className={'tw-flex-row tw-gap-0'}>
              <Paragraphs size={'xs'}>{i.label}</Paragraphs>
              <TextInput
                type={i.type}
                key={i.id}
                // label={i.label}
                register={register(i.name, {
                  required: {
                    value: true,
                    message: 'Campo requerido'
                  }
                })}
                error={errors?.usuario?.message}
              />
            </div>

          ))}
        </div>
      </div>
      <Paragraphs className={'tw-text-alert'}>{error ? error.message : ''}</Paragraphs>
      <div className={'tw-w-full tw-px-10 tw-pt-4'}>
        <Button
          disabled={loading}
          label={t('actions.Validar los datos')}
          type="submit"
          size="full"
        />
      </div>

      <div className={width >= 1280 ? 'tw-pt-24 tw-pb-4 tw-w-36' : 'tw-pt-16 tw-pb-10 tw-w-32'}>
        {colors?.logo_lg && <Logo logo={colors?.logo_lg} name={colors?.nombre}/>}
      </div>

    </form>)
  }

  if (loadingColors) {
    return null
  }

  // console.log({ colors })
  return (
    <div className="login tw-flex tw-bg-homeBlue tw-h-screen tw-w-screen tw-relative ">
      {width >= 1280 && height >= 700
        ? <>
        {/* <div className={'tw-fixed tw-translate-x-1/2 tw-translate-y-1/2 tw-w-screen tw-h-screen tw-invisible xl:tw-visible'} > */}
        {/*  <img src={Background} className={'tw-object-fit tw-h-screen tw-w-screen tw-hidden lg:tw-block'}/> */}
        {/* </div> */}
        <div className={'tw-fixed tw-translate-x-1/2 tw-translate-y-1/2 tw-w-screen tw-h-screen tw-invisible xl:tw-visible'} >
          <img src={Background} className={'tw-object-none tw-h-screen tw-w-screen tw-hidden lg:tw-block'}/>
        </div>
        <div className={'tw-fixed tw-translate-x-1/2 tw-translate-y-1/2  tw-pt-10 tw-pl-52 tw-invisible xl:tw-visible'} style={{ width: '680px', left: '49%', top: '54%' }}>
          <div className={'tw-flex tw-justify-between'}>
            <div style={{ width: '200px' }}>
              <Paragraphs color={'white'} size={'2xl'} weight={'bold'} >
                Busques
              </Paragraphs>
              <Paragraphs color={'white'} size={'2xl'} weight={'bold'} >
                l&apos;Àrea privada?
              </Paragraphs>
              <Paragraphs className={'tw-leading-5 tw-pt-4'} color={'white'} size={'lg'}>
                {t('login.bodyAP')}
              </Paragraphs>
            </div>
            <div style={{ width: '210px' }}>
              <Paragraphs color={'white'} size={'2xl'} weight={'bold'} >
               ¿Buscas
              </Paragraphs>
              <Paragraphs color={'white'} size={'2xl'} weight={'bold'} >
                el Área privada?
              </Paragraphs>
              <Paragraphs className={'tw-leading-5 tw-pt-4'} color={'white'} size={'lg'}>
                Ahora lo encontrarás dentro de Sfera Cloud, el nuevo universo inteligente para tu farmacia.
              </Paragraphs>
            </div>
          </div>

        </div>
          <section
            className={` ${width >= 1280 ? 'tw-top-1/2 tw-left-1/2' : ' tw-top-1 tw-left-1'} tw-absolute xl:tw-fixed`}
            style={{ width: '471px', height: '579px' }}>
            <div className={'tw-bg-white tw-relative tw-translate-y-1/2 tw-translate-x-1/2'} style={formStyle}>
              <ShowForm/>
            </div>
            { height >= 740 && <section className={'tw-fixed tw-w-full tw-top-1/2 tw-left-1/2'}
                                        style={{ width: '100%', maxWidth: '1800px', height: '90%' }}>
              <div className={'tw-relative tw-translate-y-1/2 tw-translate-x-1/2 tw-overflow-x-scroll'}
                   style={{ left: '-50%', bottom: height > 1300 ? '-425px' : '-38%', minHeight: '100px' }}>
                <FooterInfo height={height}/>
              </div>
            </section>}
          </section>
        </>
        : <div className={'login tw-flex tw-bg-homeBlue tw-h-screen tw-w-screen tw-relative'}>
            <div className='tw-m-auto' style={{ width: '371px', height: '579px' }}>
             <ShowForm/>
            </div>
          </div>
      }
    </div>
  )
}

export default LoginScreen
