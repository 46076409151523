import React from 'react'
import PropTypes from 'prop-types'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'

const MigrationsButton = ({ destination, label }) => {
  return (
    <div
      className={'MigrationsButton tw-text-white tw-text-center tw-w-44 tw-uppercase tw-cursor-pointer tw-opacity-90 hover:tw-opacity-100 tw-bg-violet tw-py-3 tw-rounded-full'}
      onClick={() => { window.location.href = destination }}
    >
      <Paragraphs size={'2xl'}>{label}</Paragraphs>
    </div>
  )
}

MigrationsButton.propTypes = {
  destination: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default MigrationsButton
