import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
/* eslint template-curly-spacing: ["error", "always"] */
import React, { useEffect, useState } from 'react'
import AccordeonMenu from './components/CarouselMenu'
import { SidebarContainer, SidebarExtend, SidebarInner } from './style'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types'
let timer = null
const Sidebar = () => {
  const [open, setOpen] = useState(true)
  const [showMenu, setshowMenu] = useState(false)
  const { t } = useTranslation()
  const { apps } = useSelector(state => state.corporateReducer)

  useEffect(() => {
    clearInterval(timer)
    if (open) {
      timer = setTimeout(() => {
        setshowMenu(true)
      }, 250)
    } else {
      setshowMenu(false)
    }
  }, [open])

  const getApp = () => {
    const softwares = apps.filter((app) => app.comprada)
    return softwares
  }

  return (
    <SidebarContainer className="sidebar tw-flex tw-bg-primary">
      <SidebarInner open={open}>
        <div
          onClick={() => setOpen(!open)}
          className="tw-p-2 tw-px-3 tw-flex tw-cursor-pointer tw-text-white"
        >
          <span className="material-icons-outlined">menu</span>
        </div>
        {showMenu &&
          getApp().map((i) => (
            <SidebarExtend key={i.id} open={open}>
              <AccordeonMenu url={i.url} title={t(`menu.${ i.nombre }`)}>
                {i?.items?.map((e) => {
                  const title = 'menu.' + e.name
                  return (
                    <a href={e.link} target='_blank' key={i.id} rel="noreferrer">
                      <Paragraphs size={i.size} className="tw-text-white tw-w-full">{t(title)}</Paragraphs>
                    </a>
                  )
                })}
              </AccordeonMenu>
            </SidebarExtend>
          ))}
      </SidebarInner>
    </SidebarContainer>
  )
}

Sidebar.propTypes = {}

export default Sidebar
