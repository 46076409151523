import React, { useEffect, useState } from 'react'
// import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { Trans, useTranslation } from 'react-i18next'
import ClosedDoorBG from './assets/ClosedDoorBGFull.png'
import useWindowDimensions from '../../../Login/pages/LoginScreen/hooks/useWindowsDimensions'
import MigrationsButton from '../../../../components/MigrationsButton'

const OutsideMigration = () => {
  const { t, i18n } = useTranslation()
  console.log({ lang: i18n.language })
  const { width } = useWindowDimensions()
  const [destination, setDestination] = useState('')
  const sectionStyle = {
    width: '100%',
    height: '100%',
    backgroundImage: width > 412 && `url(${ClosedDoorBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#359dd2',
    backgroundRepeat: 'no-repeat'
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const lang = queryParams.get('lang')
    if (lang) {
      i18n.changeLanguage(lang)
    }
    setDestination(queryParams.get('link') || '')
  }, [])

  return (
    <div className="login tw-flex tw-h-screen tw-fixed tw-bg-blue-500" style={sectionStyle}>
      <div className={'tw-flex tw-flex-col tw-justify-center tw-items-center tw-absolute tw-leading-tight'} style={{ paddingTop: width > 412 ? '10vh' : '1vh', marginLeft: `${width > 412 ? (width * 14) / 100 : 5}px` }}>
        <p style={{ fontFamily: 'Atkinson Hyperlegible Bold', color: 'white', fontWeight: 'bold', fontSize: '8.5vh', marginLeft: width > 412 && '-2vh', textAlign: width < 413 && 'center' }}>
          {t('migration.moment')}
        </p>
        <div className={'tw-flex tw-flex-col tw-self-center '} style={{ width: width > 412 ? '60vh' : '300px' }}>
          <Trans i18nKey={'migration.openTheDoor'} t={t} components={{ p: <span style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '4.2vh' }}/>, italic: <i/> }}/>
          <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '2.32vh', paddingTop: '4vh' }}>
            {t('migration.sinceToday')}
          </p>
          <div className={`tw-flex tw-w-full tw-justify-between tw-gap-10 ${width < 550 && ''}`} style={{ paddingTop: '3vh' }}>
            <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '1.4vh' }}>
              <li>
                {t('migration.secureEnvironment')}
              </li>
            </p>
            <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '1.4vh' }}>
              <li>
                {t('migration.easyAccess')}
              </li>
            </p>
            <p style={{ fontFamily: 'Atkinson Hyperlegible Regular', color: 'white', fontWeight: 'bold', fontSize: '1.4vh' }}>
              <li>
                {t('migration.dataUpdated')}
              </li>
            </p>
          </div>
          <Trans
            i18nKey={'migration.startNow'}
            t={t}
            components={{ p: <span style={{ fontFamily: 'Atkinson Hyperlegible Bold', color: 'white', textAlign: 'center', textDecoration: 'underline', fontSize: '2.2vh', paddingTop: '10vh' }}/>, italic: <i/>, a: <a href={destination} noreferer/>, br: <br/> }}
          />
          <div className={'tw-self-center tw-pt-10'}>
            <MigrationsButton destination={destination} label={t('migration.access')} />
          </div>
        </div>

      </div>

    </div>
  )
}

export default OutsideMigration
