import { useState } from 'react'
import useMutation from '../../../../infrastructure/hooks/useMutation'
import { LOGIN } from '../../api'
import { useDispatch } from 'react-redux'
import { actionSetTokenUser } from '../../../../infrastructure/redux/actions/user'
import axios from 'axios'

export const useLogin = () => {
  const [handleLogin] = useMutation(LOGIN, false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { URL_BASE_CORE } = require('../../../../infrastructure/constants/configurations')
  /**
   * when excute function login
   * @author   ehernandez
   * @param    {Object} data element
   * @param    {String}   data.username
   * @param    {String}   data.password
   */
  const onLogin = async (values) => {
    setLoading(true)
    const response = await axios.post(URL_BASE_CORE + '/login-app', { ...values, origin: 'sferafarma' })
      .catch(error => {
        console.log(error)
      })
    if (response?.data?.url && response?.data?.url !== 'https://sferafarma.cloud/') {
      window.location.href = response?.data?.url
      return
    }
    const { success, data } = await handleLogin({ method: 'post', data: { ...values, origin: 'sferafarma' } })
    if (success) {
      // save data into redux and local storage
      dispatch(actionSetTokenUser(data))
      return {
        success,
        data
      }
    }
    setLoading(false)
    return {
      success: false,
      error: 'Credenciales no válidas'
    }
  }
  return { onLogin, loading }
}
