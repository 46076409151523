import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const AppRoute = ({ component: Component, isAuthProtected, path, Layout }) => {
  const { token } = useSelector((state) => state.userReducer)

  return (
      <Route
        path={path}
        exact={true}
        render={() => {
          if (isAuthProtected && !token) {
            return <Redirect to="/login" />
          }
          if (Layout) {
            return (
              <Layout>
                <Component />
              </Layout>
            )
          }

          return <Component />
        }}
      />
  )
}

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthProtected: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  Layout: PropTypes.func
}

export default AppRoute
