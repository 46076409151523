import React, { useEffect, useState } from 'react'
// import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { useTranslation, Trans } from 'react-i18next'
import CloudBG from './assets/CloudBG.png'
import AreaPrivada from './assets/AreaPrivada.svg'
import BussinessIntelligence from './assets/BussinessInteligence.svg'
import FarmaOffice from './assets/Farmaoffice.svg'
import useWindowDimensions from '../../../Login/pages/LoginScreen/hooks/useWindowsDimensions'
import Campus from './assets/Campus.svg'
import Farmapremium from './assets/Farmapremium .svg'
import Planograma from './assets/Planograma.svg'
import MigrationsButton from '../../../../components/MigrationsButton'

const AlreadyMigrated = () => {
  const { t, i18n } = useTranslation()
  const [destination, setDestination] = useState('')
  const { width } = useWindowDimensions()
  console.log({ lang: i18n.language, width })
  const sectionStyle = {
    width: '100%',
    height: '100%',
    backgroundImage: width > 412 && `url(${CloudBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#359dd2',
    backgroundRepeat: 'no-repeat'
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const lang = queryParams.get('lang')
    if (lang) {
      i18n.changeLanguage(lang)
    }
    setDestination(queryParams.get('link') || '')
  }, [])

  return (
    <div className="login tw-flex tw-h-screen tw-fixed tw-bg-blue-500" style={sectionStyle}>
      <div className={'tw-flex tw-flex-col tw-justify-center tw-items-start tw-absolute tw-leading-tight'} style={{
        paddingTop: width > 412 ? '23vh' : '1vh',
        width: width > 412 ? '90vh' : '20vh',
        marginLeft: `${width > 412 ? (width * 14) / 100 : 5}px`
      }}>
        <Trans i18nKey={'migration.inTheSky'} t={t} components={{
          p: <span
            style={{ textAlign: width < 413 && 'center', fontFamily: 'Atkinson Hyperlegible Bold', color: 'white', fontWeight: 'bold', fontSize: '7.5vh' }}/>,
          italic: <i/>,
          br: <br/>
        }}/>

        <div
          className={`tw-flex tw-absolute ${width <= 412 ? 'tw-w-full tw-left-1/2' : 'tw-items-start'}`}
          style={{ marginTop: width > 412 ? '60vh' : '80vh' }}
        >
          <div
            style={{
              fontFamily: 'Atkinson Hyperlegible Regular',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1.9vh',
              width: '38vh',
              textAlign: width < 413 && 'center'
            }}
          >
            {t('migration.remember')}
            {/* <Trans i18nKey={'migration.coopWeb'} t={t} components={{ */}
            {/*  p: <div style={{ */}
            {/*    fontFamily: 'Atkinson Hyperlegible Regular', */}
            {/*    color: 'white', */}
            {/*    textDecoration: 'underline', */}
            {/*    fontSize: '2.2vh', */}
            {/*    paddingTop: '2vh' */}
            {/*  }}/>, */}
            {/*  italic: <i/>, */}
            {/*  a: <a href={destination} noreferer/>, */}
            {/*  br: <br/> */}
            {/* }}/> */}
            <div className={'tw-pt-10'}>
              <MigrationsButton destination={destination} label={t('migration.access')} />
            </div>
          </div>
        </div>

      </div>
      <div
        className={`tw-flex tw-flex-col tw-absolute ${width <= 412 && 'tw-hidden'} `}
        style={{ right: '20vh', marginTop: '60vh', width: '75vh' }}
      >
        <div className={'tw-flex tw-w-full tw-justify-between tw-items-start'}>
          <div className={'tw-flex'}>
            <img src={AreaPrivada} alt={'AreaPrivada'} width={'70vh'}/>
            <p
              className={'tw-self-center tw-pl-2'}
              style={{
                fontFamily: 'Atkinson Hyperlegible Bold',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2vh'
              }}
            >
              {t('migration.areaPrivada')}
            </p>
          </div>
          <div className={'tw-flex'}>
            <img src={BussinessIntelligence} alt={'BussinessIntelligence'} width={'70vh'}/>
            <p
              className={'tw-self-center tw-pl-2'}
              style={{
                fontFamily: 'Atkinson Hyperlegible Bold',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2vh'
              }}
            >
              Business<br/> Inteligence
            </p>
          </div>
          <div className={'tw-flex'}>
            <img src={FarmaOffice} alt={'FarmaOffice'} width={'70vh'}/>
            <p
              className={'tw-self-center tw-pl-2'}
              style={{
                fontFamily: 'Atkinson Hyperlegible Bold',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2vh'
              }}
            >
              FarmaOffice
            </p>
          </div>
        </div>
        <div className={'tw-flex tw-w-full tw-justify-between tw-pt-6'}>
          <div className={'tw-flex'}>
            <img src={Campus} alt={'Campus'} width={'70vh'}/>
            <p
              className={'tw-self-center tw-pl-2'}
              style={{
                fontFamily: 'Atkinson Hyperlegible Bold',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2vh'
              }}
            >
              {t('Campus')}
            </p>
          </div>
          <div className={'tw-flex tw-pl-10'}>
            <img src={Farmapremium} alt={'Farmapremium'} width={'70vh'}/>
            <p
              className={'tw-self-center tw-pl-2'}
              style={{
                fontFamily: 'Atkinson Hyperlegible Bold',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2vh'
              }}
            >
              Farmapremium
            </p>
          </div>
          <div className={'tw-flex'}>
            <img src={Planograma} alt={'Planograma'} width={'70vh'}/>
            <p
              className={'tw-self-center tw-pl-2'}
              style={{
                fontFamily: 'Atkinson Hyperlegible Bold',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2vh'
              }}
            >
              Planograma
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlreadyMigrated
