import styled, { keyframes } from 'styled-components'

const FadeInAnimation = keyframes`  
  from { opacity: 0; }
  to { opacity: 1; }
`

export const SidebarContainer = styled.div`
  /* background-color: #edebe9; */
`

export const SidebarInner = styled.div`
  width: ${(props) => (props.open ? '180px' : '50px')};
  transition: 0.2s linear all;
  
`

export const SidebarExtend = styled.div`
  animation-name: ${FadeInAnimation};
  animation-delay: 1.2s;
  
`
