import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'

const LangSelector = ({ onToggle }) => {
  const { i18n } = useTranslation()
  const onChangeLang = (e) => {
    i18n.changeLanguage(e)
  }

  const language = i18n.language
  return (
    <div className="langSelector tw-p-6 tw-z-50">
      <div className="tw-w-full tw-text-white">
        <div className="tw-flex tw-items-center tw-cursor-pointer">
          <div onClick={() => onChangeLang('ca')}>
            <Paragraphs
              className={`${language !== 'ca' ? 'tw-opacity-50' : ''}`}
            >
              CA
            </Paragraphs>
          </div>
          <Paragraphs className="tw-px-2">|</Paragraphs>
          <div onClick={() => onChangeLang('es')}>
            <Paragraphs
              className={`${language !== 'es' ? 'tw-opacity-50' : ''}`}
            >
              ES
            </Paragraphs>
          </div>
        </div>
      </div>
    </div>
  )
}

LangSelector.propTypes = {
  onToggle: PropTypes.func
}

export default LangSelector
