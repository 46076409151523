import AuthScreen from '../../modules/Auth/pages/AuthScreen'
import HomeScreen from '../../modules/Home/pages/HomeScreen'
import LoginScreen from '../../modules/Login/pages/LoginScreen/LoginScreen'
import OutsideMigration from '../../modules/Migration/pages/OutsideMigration'
import AlreadyMigrated from '../../modules/Migration/pages/AlreadyMigrated'
import InsideMigration from '../../modules/Migration/pages/InsideMigration'
import SonNoMigration from '../../modules/Migration/pages/SonNoMigration'
import Layout from '../components/Layout'
import AuthScreenFA from '../../modules/Auth/pages/AuthScreenFA'

const authProtectedRoutes = [
  { path: '/', component: HomeScreen, id: 2, layout: Layout }
]

const publicRoutes = [
  { path: '/auth/fa/:token', component: AuthScreenFA, id: 7 },
  { path: '/auth/:token', component: AuthScreen, id: 1 },
  { path: '/login', component: LoginScreen, id: 0 },
  { path: '/cd:lang?', component: OutsideMigration, id: 3 },
  { path: '/am:lang?', component: AlreadyMigrated, id: 4 },
  { path: '/im:lang?', component: InsideMigration, id: 5 },
  { path: '/nm:lang?', component: SonNoMigration, id: 6 }

]

export { authProtectedRoutes, publicRoutes }
