import React from 'react'
import PropTypes from 'prop-types'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'

const AccordeonMenu = ({ title, children, url }) => {
  return (
    <div className="carousel-menu tw-cursor-pointer">
      <a target='_blank' href={url} rel="noreferrer">
      <Paragraphs weight='bold' className="tw-py-3 tw-px-2 hover:tw-bg-gray-200 tw-uppercase tw-text-white">{title}</Paragraphs>
      </a>
      <div className="tw-px-4 tw-py-0">{children}</div>
    </div>
  )
}

AccordeonMenu.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  url: PropTypes.string
}

export default AccordeonMenu
